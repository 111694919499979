import { TFunction } from 'i18next';
import Image from 'next/image';
import { useParams, useRouter } from 'next/navigation';

const ChooseYourApproach = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const params = useParams();
  const router = useRouter();

  const items = [
    {
      imgSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/25020812324340_ai_chatbot-min.png',
      videoSrc: null, //'https://cdn.1cdn.app/application/LOUISEBOT/24030408595429_Chatbot.mp4',
      title: t('Home/Section4/key1').toString(),
      description: t('Home/Section4/key1-2').toString(),
      features: [
        t('Home/Section4/key1-3').toString(),
        t('Home/Section4/key1-4').toString(),
        t('Home/Section4/key1-5').toString(),
      ],
      button: { title: t('Home/Section4/key1-6').toString(), link: 'plans' },
    },
    {
      imgSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/25020812324447_chatbot_with_scenario-min.png',
      videoSrc: null, // 'https://cdn.1cdn.app/application/LOUISEBOT/2402281101415_louise_video_chatbot.mp4',
      title: t('Home/Section4/key2').toString(),
      description: t('Home/Section4/key2-2').toString(),
      features: [
        t('Home/Section4/key2-3').toString(),
        t('Home/Section4/key2-4').toString(),
        t('Home/Section4/key2-5').toString(),
      ],
      button: { title: t('Home/Section4/key2-6').toString(), link: 'plans' },
    },
    {
      imgSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/25020812332876_video_pop_up-min.png',
      videoSrc: null, //'https://cdn.1cdn.app/application/LOUISEBOT/24030408595450_Assistant_with_AI.mp4',
      title: t('Home/Section4/key3').toString(),
      description: t('Home/Section4/key3-2').toString(),
      features: [
        t('Home/Section4/key3-3').toString(),
        t('Home/Section4/key3-4').toString(),
        t('Home/Section4/key3-5').toString(),
      ],
      button: { title: t('Home/Section4/key3-6').toString(), link: 'plans' },
    },
    {
      imgSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/25020812324357_ai_clone-min.png',
      videoSrc: null, //'https://cdn.1cdn.app/application/LOUISEBOT/24030408595450_Assistant_with_AI.mp4',
      title: t('Home/Section4/key4').toString(),
      description: t('Home/Section4/key4-2').toString(),
      features: [
        t('Home/Section4/key4-3').toString(),
        t('Home/Section4/key4-4').toString(),
        t('Home/Section4/key4-5').toString(),
      ],
      button: { title: t('Home/Section4/key4-6').toString(), link: 'plans' },
    },
  ];

  const handleGoToVideoChatBot = (index: number) => {
    switch (index) {
      case 0:
        router.push(`/${params?.lang ?? 'en-US'}/text-chatbot`);
        break;
        break;
      case 1:
        router.push(`/${params?.lang ?? 'en-US'}/video-chatbot`);
        break;
      case 2:
        router.push(`/${params?.lang ?? 'en-US'}/ai-chatbot`);
        break;
      default:
        break;
    }
  };

  return (
    <section className="tw-flex tw-justify-center tw-my-32" aria-label="Choose Your Approach">
      <div className="tw-max-w-[1280px] tw-px-8 tw-flex-col tw-justify-start tw-items-center tw-gap-16 tw-inline-flex">
        <header className="tw-self-stretch tw-text-center">
          <h2 className="tw-text-gray-800 tw-text-4xl tw-font-bold tw-font-['Lexend'] tw-leading-10">
            {t('Home/Section4/key0').toString()}
          </h2>
          <p className="tw-text-gray-500 tw-text-lg tw-font-normal tw-font-['Lexend'] tw-leading-7">
            Discover Our AI-Powered Services
          </p>
        </header>
        <div className="tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-gap-16">
          {items.map((item, index) => (
            <article key={index} className="tw-flex-col tw-justify-start tw-items-start tw-gap-3 tw-inline-flex">
              {item.videoSrc ? (
                <video className="tw-rounded-2xl" src={item.videoSrc} autoPlay loop muted playsInline />
              ) : (
                <Image
                  className="tw-rounded-2xl"
                  src={item.imgSrc}
                  width={500}
                  height={364}
                  alt={`AI Service: ${item.title}`}
                  title={item.title}
                />
              )}
              <h3 className="tw-text-gray-800 tw-text-2xl tw-font-semibold tw-font-['Lexend'] tw-leading-loose">
                {item.title}
              </h3>
              <p className="tw-text-gray-500 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                {item.description}
              </p>
              <ul className="tw-list-disc tw-ps-5">
                {item.features.map((feature, index) => (
                  <li
                    key={index}
                    className="tw-text-gray-500 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal"
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </article>
          ))}
        </div>
        <a
          href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL}/${params?.lang ?? 'en-US'}/register`}
          className="tw-text-white tw-px-[41px] tw-py-[17px] tw-bg-blue-600 tw-rounded-[99px] tw-shadow-lg tw-justify-center tw-items-center tw-inline-flex tw-overflow-hidden"
          aria-label="Register for our AI services"
        >
          {t('Home/Section11/key4')}
        </a>
      </div>
    </section>
  );
};

export default ChooseYourApproach;
