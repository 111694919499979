import LinkToDashboard from '@/components/common/LinkToDashboard';
import { TFunction } from 'i18next';
import Image from 'next/image';
import { useParams } from 'next/navigation';

export default function BuildSmarter({ t }: { t: TFunction<'translation', undefined> }) {
  const params = useParams();

  const steps = [
    {
      title: t('BuildSmarter/step1/Title'),
      description: t('BuildSmarter/step1/Description'),
      button: t('BuildSmarter/step1/Button'),
      imagePosition: 'left',
      imageUrl:
        'https://cdn.1cdn.app/application/LOUISEBOT/25020812324346_build_smarter_chatbots_with_powerful_new_features-min.png',
    },
    {
      title: t('BuildSmarter/step2/Title'),
      description: t('BuildSmarter/step2/Description'),
      button: t('BuildSmarter/step2/Button'),
      imagePosition: 'right',
      imageUrl:
        'https://cdn.1cdn.app/application/LOUISEBOT/25020812324395_access_and_export_chat_histories_with_ease-min.png',
    },
  ];

  return (
    <section className="tw-w-full tw-flex tw-justify-center tw-bg-white">
      <div className="tw-max-w-[1280px] lg:tw-py-16 tw-flex tw-flex-col tw-items-center tw-gap-16">
        <article className={`tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-gap-16 `}>
          {/* Image Placeholder */}

          <Image
            className={`tw-w-full tw-object-contain  tw-rounded-3xl ${
              steps[0].imagePosition === 'right' ? 'lg:tw-order-last' : ''
            }`}
            width={500}
            height={534}
            src={steps[0].imageUrl}
            alt=""
            title=""
          />

          {/* Text Content */}
          <div className="tw-flex tw-flex-col tw-gap-6 tw-py-8 lg:tw-py-16 ">
            <h2 className="tw-text-gray-800 tw-text-4xl tw-font-bold tw-font-['Lexend']">{steps[0].title}</h2>
            <p className="tw-text-gray-500 tw-text-lg tw-font-normal tw-font-['Lexend']">{steps[0].description}</p>
            <div>
              <LinkToDashboard
                params={params}
                href={`register`}
                className="tw-px-6 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded-full tw-text-sm tw-font-['Lexend']"
              >
                {steps[0].button}
              </LinkToDashboard>
            </div>
          </div>
        </article>
        <article className={`tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-gap-16 `}>
          {/* Image Placeholder */}

          <Image
            className={`tw-w-full tw-object-contain   tw-rounded-3xl ${
              steps[1].imagePosition === 'right' ? 'lg:tw-order-last' : ''
            }`}
            width={500}
            height={534}
            src={steps[1].imageUrl}
            alt=""
            title=""
          />

          {/* Text Content */}
          <div className="tw-flex tw-flex-col tw-gap-6 tw-py-8 lg:tw-py-16 ">
            <h2 className="tw-text-gray-800 tw-text-4xl tw-font-bold tw-font-['Lexend']">{steps[1].title}</h2>
            <p className="tw-text-gray-500 tw-text-lg tw-font-normal tw-font-['Lexend']">{steps[1].description}</p>
            <div>
              <LinkToDashboard
                params={params}
                href={`register`}
                className="tw-px-6 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded-full tw-text-sm tw-font-['Lexend']"
              >
                {steps[1].button}
              </LinkToDashboard>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}
