'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import { Col, Container, Row } from 'react-bootstrap';
import { BsStarFill } from 'react-icons/bs';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Testimonials } from '@/constants/testemonials.constant';
import { TFunction } from 'i18next';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import 'swiper/css';

const Section5 = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const [swiper, setSwiper] = useState<any>();

  return (
    <section className="sec-5">
      <Container fluid>
        <Row className="g-3 align-items-center">
          <Col sm={1} className="d-sm-flex d-none align-items-center justify-content-center">
            <div className="navigation-item" onClick={() => swiper?.slidePrev()}>
              <BiChevronLeft size={22} color="#9CA3AF" />
            </div>
          </Col>
          <Col sm={10} xs={12}>
            <Swiper
              modules={[Autoplay]}
              autoplay={true}
              onInit={(ev) => {
                setSwiper(ev);
              }}
              autoHeight={false}
            >
              {Testimonials.map((i, el) => {
                return (
                  <SwiperSlide key={el}>
                    <div className="testimonial-item">
                      <div className="tw-flex  ">
                        <div className="stars">
                          <BsStarFill className="color-nineteenColor" />
                          <BsStarFill className="color-nineteenColor" />
                          <BsStarFill className="color-nineteenColor" />
                          <BsStarFill className="color-nineteenColor" />
                          <BsStarFill className="color-nineteenColor" />
                          <span className="tw-text-gray-800 tw-text-lg tw-font-semibold tw-font-['Lexend'] tw-leading-7">
                            {i.star}.0
                          </span>
                        </div>
                        <span className="tw-mx-1  tw-text-gray-400 tw-text-lg tw-font-semibold tw-font-['Lexend'] tw-leading-7">
                          / 5
                        </span>
                      </div>

                      <p className="tw-text-gray-800 tw-text-3xl tw-font-semibold tw-font-['Lexend'] tw-leading-9">
                        “{t(i.description)}”
                      </p>

                      <div className="tw-mt-10 tw-flex tw-items-center tw-gap-2">
                        <Image
                          alt={i.fullName}
                          src={i.image as string}
                          title={i.image as string}
                          className="tw-w-10 tw-rounded-full"
                          width={40}
                          height={40}
                        />
                        <div className="tw-flex tw-flex-col">
                          <span className="tw-text-gray-800 tw-text-base tw-font-semibold tw-font-['Lexend'] tw-leading-normal">
                            {i.fullName}
                          </span>
                          <span className="tw-text-gray-400 tw-text-xs tw-font-normal tw-font-['Lexend'] tw-leading-none">
                            {t(i.job)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Col>
          <Col xs={1} className="d-sm-flex d-none align-items-center justify-content-center">
            <div className="navigation-item" onClick={() => swiper?.slideNext()}>
              <BiChevronRight size={22} color="#9CA3AF" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section5;
