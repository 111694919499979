'use client';
import React from 'react';
import LinkToDashboard from './common/LinkToDashboard';
import { useParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../i18n-config';
import LinkTo from './common/LinkTo';

function FooterBaner({
  title,
  description,
  linkToDashboard,
  buttonTitle,
  linkToSite,
  moreDetails,
}: {
  title: string;
  description: string;
  buttonTitle: string;
  linkToDashboard?: string;
  linkToSite?: string;
  moreDetails?: any;
}) {
  const params = useParams();
  const { t } = useTranslation((params?.lang as Locale) || 'defaultLocale');

  return (
    <div className="tw-w-full md:tw-px-16 tw-px-3 lg:tw-py-12 tw-py-6 tw-bg-blue-700 tw-rounded-2xl tw-justify-between tw-items-center tw-flex md:tw-flex-row tw-flex-col">
      <div className=" tw-pb-2 tw-flex-col tw-justify-start tw-items-start tw-gap-3 tw-inline-flex">
        <div className="tw-self-stretch tw-text-gray-50 tw-text-4xl tw-font-semibold tw-font-['Lexend'] tw-leading-10">
          {title}
        </div>
        <div className="tw-text-gray-50 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
          {description}
        </div>
      </div>
      <div className="tw-py-1 tw-flex-col tw-justify-between tw-items-end tw-inline-flex tw-w-full">
        {linkToDashboard && (
          <LinkToDashboard
            params={params}
            href={linkToDashboard}
            className="tw-px-6 tw-py-3 tw-bg-gray-800 tw-rounded-full tw-justify-start tw-items-start tw-inline-flex"
          >
            <div className="tw-text-gray-50 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
              {buttonTitle}
            </div>
          </LinkToDashboard>
        )}
        {linkToSite && (
          <LinkTo
            href={linkToSite}
            className="tw-px-6 tw-py-3 tw-bg-gray-800 tw-rounded-full tw-justify-start tw-items-start tw-inline-flex"
          >
            <div className="tw-text-gray-50 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
              {buttonTitle}
            </div>
          </LinkTo>
        )}

        <div className="tw-flex tw-gap-3 tw-mt-4 text-center tw-text-gray-50 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal tw-w-full">
          {moreDetails}
        </div>
      </div>
    </div>
  );
}

export default FooterBaner;
