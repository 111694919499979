import Link from 'next/link';
import React from 'react';

type Props = { params: any; href: string; className: string; children: any };

function LinkToDashboard({ params, href, className, children }: Props) {
  return (
    <Link
      target="_blank"
      href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL as string}/${
        params?.lang ? ((params.lang as string).startsWith('en') ? 'en-US' : 'fr-FR') : 'en-US'
      }/${href}`}
      className={className + ' tw-cursor-pointer'}
    >
      {children}
    </Link>
  );
}

export default LinkToDashboard;
