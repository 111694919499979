import { TFunction } from 'i18next';

export default function TransformYourStrategy({ t }: { t: TFunction<'translation', undefined> }) {
  const items = [
    {
      iconicText: t('Home/Section3/key1').toString(),
      title: t('Home/Section3/key1-2').toString(),
      description: t('Home/Section3/key1-3').toString(),
    },
    {
      iconicText: t('Home/Section3/key2').toString(),
      title: t('Home/Section3/key2-2').toString(),
      description: t('Home/Section3/key2-3').toString(),
    },
    {
      iconicText: t('Home/Section3/key3').toString(),
      title: t('Home/Section3/key3-2').toString(),
      description: t('Home/Section3/key3-3').toString(),
    },
  ];

  return (
    <section className="tw-flex tw-justify-center tw-my-24">
      <div className="tw-max-w-[1280px] tw-px-8 tw-flex-col tw-justify-start tw-items-center tw-gap-16 tw-inline-flex">
        {/* Main heading */}
        <h2 className="tw-text-center tw-text-gray-800 tw-text-4xl tw-font-bold tw-font-['Lexend'] tw-leading-10">
          {t('Home/Section3/key0').toString()}
        </h2>
        {/* Content Section */}
        <div className="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6">
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full lg:tw-w-1/3">
            <div className="tw-w-[120px] tw-h-[120px] tw-bg-gray-200 tw-rounded-2xl tw-flex tw-justify-center tw-items-center">
              <span className="tw-text-blue-600 tw-text-4xl tw-font-bold tw-font-['Lexend']">
                {items[0].iconicText}
              </span>
            </div>
            <div className="tw-text-center tw-space-y-2 tw-flex tw-flex-col tw-items-center">
              <h3 className="tw-text-gray-800 tw-text-xl tw-font-semibold tw-font-['Lexend'] tw-max-w-[300px]">
                {items[0].title}
              </h3>
              <p className="tw-text-gray-500 tw-text-base tw-font-normal tw-font-['Lexend']">{items[0].description}</p>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full lg:tw-w-1/3">
            <div className="tw-w-[120px] tw-h-[120px] tw-bg-gray-200 tw-rounded-2xl tw-flex tw-justify-center tw-items-center">
              <span className="tw-text-blue-600 tw-text-4xl tw-font-bold tw-font-['Lexend']">
                {items[1].iconicText}
              </span>
            </div>
            <div className="tw-text-center tw-space-y-2 tw-flex tw-flex-col tw-items-center">
              <h3 className="tw-text-gray-800 tw-text-xl tw-font-semibold tw-font-['Lexend'] tw-max-w-[170px]">
                {items[1].title}
              </h3>
              <p className="tw-text-gray-500 tw-text-base tw-font-normal tw-font-['Lexend']">{items[1].description}</p>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full lg:tw-w-1/3">
            <div className="tw-w-[120px] tw-h-[120px] tw-bg-gray-200 tw-rounded-2xl tw-flex tw-justify-center tw-items-center">
              <span className="tw-text-blue-600 tw-text-4xl tw-font-bold tw-font-['Lexend']">
                {items[2].iconicText}
              </span>
            </div>
            <div className="tw-text-center tw-space-y-2 tw-flex tw-flex-col tw-items-center">
              <h3 className="tw-text-gray-800 tw-text-xl tw-font-semibold tw-font-['Lexend'] tw-max-w-[260px]">
                {items[2].title}
              </h3>
              <p className="tw-text-gray-500 tw-text-base tw-font-normal tw-font-['Lexend']">{items[2].description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
